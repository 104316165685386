export enum EResource {
    GEO_FENCE = 'geofence',
    INDOOR_LAYOUT = 'geofence',
    USERS = 'users',
    DEVICES = 'devices',
    DEVICE_SENSORS = 'sensors',
    INDOOR_ASSETS = 'indoor',
    DEVICE_SETTINGS = 'deviceSettings',
    RECIPIENTS = 'users',
    REPORTS = 'reports',
    ALERTS = 'alerts',
    ORGANISATION = 'organisation',
    EVENTS = 'events',
    GENERAL = 'general',
    ROLES = 'roles',
    SENSORS = 'sensors',
    GROUPS = 'groups',
    VEHICLES = 'vehicles',
    ACTIVITY = 'activity',
    MESSAGES = 'messages',
    NOTIFICATIONS = 'notifications',
    DRIVERS = 'drivers',
    TRIPS = 'trips',
}
