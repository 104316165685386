import {CloseOutlined} from "@ant-design/icons";
import {Button, Drawer, Form, message, Space, Spin} from "antd";
import SensorTabbedForm from "components/sensor-form/SensorTabbedForm";
import {
    ISensorSaveRequest,
    isSensorBaseCreationResponse,
    SensorBase,
    SensorBaseSerializer
} from "contracts/holotrak/sensorBase";
import React, {useCallback, useEffect} from 'react';
import {useSyncDevicesMutation} from "redux/services/express";
import { selectFilteredDevices } from "redux/services/express";
import {useGetDeviceSensorDetailsQuery} from "redux/services/losant/devices";
import {useCreateSensorMutation, useUpdateSensorMutation} from "redux/services/losant/sensors";
import { useTypedSelector } from "redux/store";

interface IDeviceSensorFormProps {
    isOpen: boolean;
    onClose: () => void;
    sensor: SensorBase;
}

const formFieldsToUse = [
    'id',
    'name',
    'mobileId',
    'linkedDevice'
];

const DeviceSensorForm: React.FC<IDeviceSensorFormProps> = ({sensor, onClose, isOpen}) => {
    const [deviceSensorForm] = Form.useForm<Partial<SensorBase>>();

    const [messageApi, contextHolder] = message.useMessage();

    const {sensorDetails, isLoadingSensorDetails} = useGetDeviceSensorDetailsQuery(sensor?.id, {
        skip: !sensor?.id,
        selectFromResult: ({data, isLoading}) => {
            return {
                sensorDetails: SensorBaseSerializer.parse(data),
                isLoadingSensorDetails: isLoading
            };
        }
    });

    const [syncDevices, { isLoading: isSyncingItems }] = useSyncDevicesMutation();

    const [updateSensor, {isLoading: isUpdatingSensor}] = useUpdateSensorMutation();
    const [createSensor, {isLoading: isCreatingSensor}] = useCreateSensorMutation();

    const devices = useTypedSelector(selectFilteredDevices);

    const onDrawerClose = useCallback(() => {
        deviceSensorForm.resetFields();
        if (typeof onClose === 'function') {
            onClose();
        }
    }, [deviceSensorForm, onClose]);


    const handleDriverFormSubmit = () => {
        deviceSensorForm.validateFields().then(async values => {
            const formValuesToSend: ISensorSaveRequest = {
                data: values
            };

            const hideSaveSensorNotification = messageApi.loading("Saving Sensor...", 0);

            if (formValuesToSend.data.id) {
                updateSensor(formValuesToSend).then(() => {
                    syncDevices([sensor]).then(() => {
                        hideSaveSensorNotification();
                        message.success("Sensor updated successfully.");
                    });
                });
            } else {
                createSensor(formValuesToSend).then((response) => {
                    if (isSensorBaseCreationResponse(response)) {
                        syncDevices([SensorBaseSerializer.parse(response.data)]).then(() => {
                            hideSaveSensorNotification();
                            message.success("Sensor created successfully.");
                        });
                    } else {
                        hideSaveSensorNotification();
                        message.warning("Sensor creation was interrupted.");
                    }
                    onClose();
                });
            }
        });
    }

    useEffect(() => {
        deviceSensorForm.resetFields();
        if (sensorDetails && !isLoadingSensorDetails && sensor?.id === sensorDetails.id) {
            formFieldsToUse.forEach((field) => {
                deviceSensorForm.setFieldsValue({
                    [field]: sensorDetails[field]
                });
            });
        }
    }, [sensorDetails, deviceSensorForm, isLoadingSensorDetails]);

    return (
        <Drawer
            title={`Sensor`}
            closable={false}
            onClose={onDrawerClose}
            size={'large'}
            open={isOpen}
            extra={
                <Space>
                    <Button
                        htmlType="reset"
                        className="grey-btn"
                        onClick={() => deviceSensorForm.resetFields()}
                        loading={isUpdatingSensor || isCreatingSensor || isLoadingSensorDetails || isSyncingItems}
                    >
                        Reset
                    </Button>
                    <Button
                        htmlType="submit"
                        className="grey-btn"
                        onClick={handleDriverFormSubmit}
                        loading={isUpdatingSensor || isCreatingSensor || isLoadingSensorDetails || isSyncingItems}
                    >
                        {sensor?.id ? 'Update' : 'Create'}
                    </Button>
                    <Button
                        onClick={onClose}
                        type="link"
                        icon={<CloseOutlined/>}
                    />
                </Space>
            }
        >
            {contextHolder}
            <Spin
                spinning={isUpdatingSensor || isCreatingSensor || isLoadingSensorDetails || isSyncingItems}
            >
                <Form
                    name='driver-form'
                    form={deviceSensorForm}
                    onFinish={handleDriverFormSubmit}
                >
                    <Form.Item
                        dependencies={formFieldsToUse}
                    >
                        {
                            (form) => <SensorTabbedForm devices={devices}/>
                        }
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
}


export default DeviceSensorForm;
