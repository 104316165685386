import {DeviceBase} from "contracts/holotrak/deviceBase";
import {LosantPagination} from "contracts/holotrak/losantPagination";
import {IHasTags} from "contracts/IHasTags";
import {IOutdoorLocatable, IReportableItem} from "contracts/IOutdoorLocatable";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";


@jsonObject
export class SensorBase extends DeviceBase implements IHasTags, IOutdoorLocatable, IReportableItem {
    @jsonMember(String)
    linkedDevice?: string;

    get macAddress(): string {
        return this.getTagValue('mobileId');
    }

    set macAddress(value: string) {
        if (this.tags && Array.isArray(this.tags)) {
            const mobileId = this.tags.indexOf(this.tags.find(tag => tag.key === 'mobileId'));

            if (mobileId === -1) {
                this.tags.push({key: 'mobileId', value})
            } else {
                this.tags[mobileId].value = value;
            }
        }
    }
}


@jsonObject()
export class SensorReportResponse extends LosantPagination {
    @jsonArrayMember(SensorBase)
    items: SensorBase[] = [];
}

export type ISensorSaveRequest = {
    data: Partial<SensorBase>;
}

export interface SensorBaseCreationResponse {
    data: SensorBase;
}

export function isSensorBaseCreationResponse(item: any): item is SensorBaseCreationResponse {
    return item && item.data && item.data.id;
}

export const SensorBaseSerializer = new TypedJSON(SensorBase);
